import { createTheme } from "@mui/material";
// @ts-ignore
import styleVariables from "../variables.scss";

const commonTheme = createTheme({
    typography: {
        fontFamily: `"Satoshi", sans-serif`,
        button: {
            textTransform: "none"
        }
    },
    components: {
        MuiButton: {
            defaultProps: {
                disableRipple: true
            },
            styleOverrides: {
                root: {
                    padding: "10px 30px",
                    boxSizing: "border-box"
                }
            }
        },
        MuiFormControl: {
            defaultProps: {
                fullWidth: true
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 8
                }
            }
        }
    }
});

export const muiTheme = createTheme({
    ...commonTheme,
    palette: {
        mode: "light",
        primary: {
            main: styleVariables.blue,
            contrastText: styleVariables.white
        }
    }
});

export const darkMuiTheme = createTheme({
    ...commonTheme,
    palette: {
        mode: "dark",
        primary: {
            main: styleVariables.darkblue,
            contrastText: styleVariables.white
        },
        secondary: {
            main: styleVariables.white
        }
    }
});
