import { AppBar, Box, Button, Container, ThemeProvider, Toolbar, Typography, useScrollTrigger } from "@mui/material";
import { useState } from "react";

import BurgerIcon from "../../assets/burger_icon.png";
import DefiDevShopLogo from "../../assets/defidevshoplogo";

import { getSideBarMenu } from "pages/Home/home.constants";
import { Drawer } from "../Drawer";
import { SidebarMenuItem } from "components/SideBarMenuItem";
import { useLocation, useNavigate } from "react-router-dom";
import { darkMuiTheme, muiTheme } from "styles/theme/mui";

// @ts-ignore
import styleVariables from "../../styles/variables.scss";

const pages = ["Services", "About"];
const LIGHT_MODE = [/\/contact-us*/];

export const DefiDevShopAppBar = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    const isLightMode = LIGHT_MODE.some(path => path.test(location.pathname));

    const logoColor = isLightMode
        ? styleVariables.white
        : undefined;
    const backgroundColor = isLightMode
        ? styleVariables.blue
        : styleVariables.darkblue;

    const scrollTrigger = useScrollTrigger();

    return (
        <ThemeProvider theme={
            isLightMode
                ? darkMuiTheme
                : muiTheme
        }
        >
            <AppBar
                position="sticky"
                sx={{
                    backgroundColor,
                    backgroundImage: "unset"
                }}
                elevation={scrollTrigger ? 6 : 0}
            >
                <Container>
                    <Toolbar
                        sx={{
                            my: 2
                        }}
                    >
                        <div
                            style={{
                                cursor: "pointer"
                            }}
                            onClick={() => navigate("/")}
                        >
                            <DefiDevShopLogo logoFill={logoColor} width={180} height={51} />
                        </div>
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: {
                                    xs: "none",
                                    md: "flex"
                                },
                                mx: 5
                            }}
                            gap={1}
                        >
                            {false &&
                                pages.map(page => (
                                    <Button key={page} sx={{ my: 2, color: styleVariables.white, display: "block" }}>
                                        {page}
                                    </Button>
                                ))}
                        </Box >
                        <Box
                            sx={{
                                flexGrow: 0,
                                display: "flex",
                                width: {
                                    xs: "100%",
                                    md: "unset"
                                }
                            }}
                            gap={1}
                        >
                            {false && (
                                <Button
                                    variant="text"
                                    sx={{
                                        my: 2,
                                        color: styleVariables.white,
                                        display: {
                                            xs: "none",
                                            md: "block"
                                        }
                                    }}
                                    onClick={() => navigate("/contact-us")}
                                >
                                    Contact
                                </Button>
                            )}
                            <Button
                                variant="contained"
                                sx={{
                                    my: 2,
                                    color: styleVariables.white,
                                    display: {
                                        xs: "none",
                                        md: "block"
                                    },
                                    marginLeft: "auto"
                                }}
                                onClick={() => navigate("/contact-us")}
                            >
                                Get Quote
                            </Button>
                            <Button
                                onClick={() => setIsDrawerOpen(true)}
                                sx={{
                                    width: 0,
                                    display: {
                                        xs: "block",
                                        md: "none"
                                    },
                                    marginLeft: {
                                        xs: "auto"
                                    }
                                }}
                            >
                                <Box component="img" src={BurgerIcon} />
                            </Button>
                        </Box >
                        <Drawer
                            anchor={"right"}
                            open={isDrawerOpen}
                            onClose={() => setIsDrawerOpen(false)}
                            sx={{
                                display: {
                                    xs: "flex",
                                    md: "none"
                                }
                            }}
                        >
                            <Button
                                onClick={() => setIsDrawerOpen(false)}
                                sx={{
                                    display: {
                                        xs: "block",
                                        md: "none"
                                    },
                                    marginLeft: {
                                        xs: "auto"
                                    },
                                    color: styleVariables.white
                                }}
                            >
                                x
                            </Button>
                            {false &&
                                getSideBarMenu({ navigate }).map(menuItem => (
                                    <>
                                        <Typography
                                            variant="subtitle2"
                                            fontWeight={400}
                                            fontSize={14}
                                            sx={{
                                                my: 1,
                                                opacity: 0.7
                                            }}
                                        >
                                            {menuItem.header}
                                        </Typography>
                                        {menuItem.items.map(item => (
                                            <SidebarMenuItem {...item} />
                                        ))}
                                    </>
                                ))}
                            <Button
                                variant="outlined"
                                sx={{
                                    color: styleVariables.white,
                                    borderColor: styleVariables.white,
                                    my: 2
                                }}
                                onClick={() => navigate("/contact-us")}
                            >
                                Get Quote
                            </Button>
                        </Drawer >
                    </Toolbar >
                </Container >
            </AppBar >
        </ThemeProvider >
    );
};
