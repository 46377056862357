const DeFiDevShopIcon = ({ logoFill = "#4A37FF", width = 733, height = 151 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 733 151" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M218.03 101.3V40.0601H236.5C254.96 40.0601 267.27 52.3701 267.27 70.6601C267.27 88.9501 255.05 101.26 236.67 101.26L218.03 101.3ZM224.19 45.7401V95.66H236.41C251.41 95.66 260.94 85.9201 260.94 70.6601C260.94 55.4001 251.37 45.6601 236.24 45.6601L224.19 45.7401Z"
                fill="#F1F2F2"
            />
            <path
                d="M274.2 80.6198C274.2 67.7098 282.2 58.8198 294.03 58.8198C305.22 58.8198 312.83 66.7698 312.83 78.4798V81.3898H279.92C280.35 91.4698 285.65 97.2898 294.54 97.2898C301.29 97.2898 305.65 94.3798 307.19 88.9098H312.83C310.61 97.6298 304.2 102.33 294.45 102.33C282.28 102.33 274.2 93.6098 274.2 80.6198ZM280.09 77.1098H306.93C306.93 69.1598 301.81 63.8598 294.03 63.8598C286.25 63.8598 281.12 68.9898 280.09 77.1098Z"
                fill="#F1F2F2"
            />
            <path
                d="M329.67 74.3801V101.3H323.52V40.0601H359.59V45.7001H329.67V68.7801H354.72V74.3401L329.67 74.3801Z"
                fill="#F1F2F2"
            />
            <path
                d="M367.2 44.2003C367.202 43.0372 367.66 41.9213 368.475 41.0914C369.29 40.2616 370.397 39.7838 371.56 39.7603C372.733 39.7782 373.853 40.2515 374.683 41.08C375.514 41.9085 375.989 43.0274 376.01 44.2003C375.986 45.3648 375.507 46.4737 374.675 47.2888C373.843 48.104 372.725 48.5605 371.56 48.5602C370.405 48.555 369.299 48.0939 368.483 47.2774C367.666 46.4609 367.205 45.355 367.2 44.2003ZM368.66 59.9302H374.55V101.3H368.66V59.9302Z"
                fill="#F1F2F2"
            />
            <path
                d="M387.28 101.3V38.0601H411.28C429.82 38.0601 442.73 51.06 442.73 69.86C442.73 88.32 430.17 101.31 412.13 101.31L387.28 101.3ZM401.13 50.8701V88.48H410.7C421.64 88.48 428.05 81.5601 428.05 69.8501C428.05 57.8501 421.3 50.8501 409.85 50.8501L401.13 50.8701Z"
                fill="#F1F2F2"
            />
            <path
                d="M448.41 79.8502C448.41 66.5102 457.41 57.2002 470.3 57.2002C483.72 57.2002 492.09 66.0002 492.09 79.9302V83.2702L461.09 83.4402C461.86 89.0802 465.03 91.7302 470.84 91.7302C475.84 91.7302 479.3 89.9302 480.24 86.8602H492.28C490.74 96.3502 482.28 102.41 470.57 102.41C457.64 102.41 448.41 93.0602 448.41 79.8502ZM461.28 75.3202H479.06C479.06 70.7902 475.73 67.8802 470.43 67.8802C465.13 67.8802 462.28 70.1902 461.28 75.3202Z"
                fill="#F1F2F2"
            />
            <path
                d="M493.21 58.5601H507.4L512.61 73.5601C514.239 78.0152 515.637 82.5512 516.8 87.1501C518.028 82.5309 519.541 77.9922 521.33 73.5601L527.06 58.5601H540.73L522.87 101.3H509.87L493.21 58.5601Z"
                fill="#F1F2F2"
            />
            <path
                d="M565.44 38.9902C576.44 38.9902 583.73 45.1402 584.67 55.4002H578.43C577.75 48.4802 573.05 44.5502 565.36 44.5502C556.72 44.5502 551.08 48.9902 551.08 55.9102C551.08 61.3002 554.24 64.7202 560.65 66.3402L571.17 68.9102C580.74 71.2102 585.7 76.6903 585.7 84.9803C585.7 95.5803 577.49 102.33 564.84 102.33C552.96 102.33 544.93 96.0902 544.16 86.0902H550.48C550.82 92.5002 556.48 96.6902 564.84 96.6902C573.84 96.6902 579.54 92.3303 579.54 85.3203C579.54 79.8503 576.47 76.2602 569.89 74.7202L559.54 72.1602C549.97 69.8502 544.93 64.3802 544.93 56.0002C544.93 45.7402 553.13 38.9902 565.44 38.9902Z"
                fill="#F1F2F2"
            />
            <path
                d="M595.45 101.22V38.9902H601.34V67.1102C603.99 62.1102 609.04 58.8203 615.96 58.8203C626.82 58.8203 631.69 65.5702 631.69 76.0002V101.3H625.79V77.3002C625.79 67.6402 621.26 64.2303 614.85 64.2303C605.96 64.2303 601.34 70.8103 601.34 78.7603V101.24L595.45 101.22Z"
                fill="#F1F2F2"
            />
            <path
                d="M661.61 58.8198C673.75 58.8198 682.61 67.9698 682.61 80.6198C682.61 93.2698 673.72 102.33 661.61 102.33C649.5 102.33 640.61 93.1798 640.61 80.6198C640.61 68.0598 649.47 58.8198 661.61 58.8198ZM661.61 97.0298C670.41 97.0298 676.61 90.2798 676.61 80.5298C676.61 70.9598 670.37 64.1198 661.61 64.1198C652.85 64.1198 646.61 70.9598 646.61 80.5298C646.57 90.2798 652.72 97.0598 661.61 97.0598V97.0298Z"
                fill="#F1F2F2"
            />
            <path
                d="M692.05 59.9298H697.17L697.77 68.3098C700.68 61.8998 706.49 58.8198 713.41 58.8198C725.41 58.8198 732.56 67.9698 732.56 80.4498C732.56 92.9298 725.64 102.33 713.41 102.33C706.41 102.33 700.85 99.3298 697.94 93.3298V119.33H692.05V59.9298ZM712.39 97.0598C721.39 97.0598 726.58 90.1398 726.58 80.6498C726.58 71.1598 721.36 64.2398 712.39 64.2398C703.42 64.2398 698.03 71.0698 698.03 80.6498C698.03 90.2298 703.33 97.0598 712.39 97.0598Z"
                fill="#F1F2F2"
            />
            <path d="M88.976 60.2679L74.1833 75.0605L88.976 89.8532L103.769 75.0605L88.976 60.2679Z" fill={logoFill} />
            <path
                d="M88.98 0V31.83C100.445 31.83 111.441 36.3846 119.548 44.4918C127.655 52.599 132.21 63.5947 132.21 75.06C132.21 86.5253 127.655 97.521 119.548 105.628C111.441 113.735 100.445 118.29 88.98 118.29V150.12C108.887 150.12 127.979 142.212 142.055 128.135C156.132 114.059 164.04 94.9671 164.04 75.06C164.04 55.1528 156.132 36.061 142.055 21.9846C127.979 7.90808 108.887 0 88.98 0Z"
                fill={logoFill}
            />
            <path d="M11.8201 80.9001V69.0801H6.10352e-05V80.9001H11.8201Z" fill={logoFill} />
            <path d="M43.8701 60.27V39.02H22.6201V60.27H43.8701Z" fill={logoFill} />
            <path d="M22.6199 89.8501V111.1H43.8699V89.8501H22.6199Z" fill={logoFill} />
            <path d="M66.48 21.6701V9.8501H54.66V21.6701H66.48Z" fill={logoFill} />
            <path d="M54.6699 128.18V140H66.4899V128.18H54.6699Z" fill={logoFill} />
        </svg>
    );
};

export default DeFiDevShopIcon;
