import { Avatar, Box, Typography } from "@mui/material";

import styles from "./layerIcon.module.scss";

interface LayerIconProps {
    title: string;
    image: string;
}

export const LayerIcon = ({ title, image }: LayerIconProps) => {
    return (
        <Box className={styles.container}>
            <Avatar
                alt={title}
                src={image}
                imgProps={{
                    sx: {
                        width: "unset",
                        height: "unset"
                    }
                }}
                sx={{
                    width: 60,
                    height: 60,
                    border: "1px solid"
                }}
                className={styles.image}
            />
            <Typography
                variant="subtitle1"
                sx={{
                    color: "white"
                }}
                className={styles.title}
            >
                {title}
            </Typography>
        </Box>
    );
};
