import { Box, Typography } from "@mui/material";

import cn from "classnames";

import styles from "./sliderImage.module.scss";

interface SliderImageProps {
    title: string;
    image: string;
    className?: string;
}

export const SliderImage = ({ title, image, className }: SliderImageProps) => {
    return (
        <Box className={cn(styles.container, className)}>
            <Box component="img" alt={title} src={image} className={styles.image} />
            <Typography
                variant="subtitle1"
                sx={{
                    color: "white"
                }}
                className={styles.title}
            >
                {title}
            </Typography>
        </Box>
    );
};
