import cn from "classnames";
import styles from "./home.module.scss";

import EtheriumLogo from "../../assets/etherium_logo_2.png";
import HyperledgerLogo from "../../assets/hyperledger_logo.png";
import CordaLogo from "../../assets/corda_logo.png";
import MultichainLogo from "../../assets/multichain_logo.png";
import EosioLogo from "../../assets/eosio_logo.png";
import HederaLogo from "../../assets/hedera_logo.png";
import StellarLogo from "../../assets/stellar_logo.png";
import TronLogo from "../../assets/tron_logo.png";

import JsLogo from "../../assets/javascript_logo.png";
import NodeJsLogo from "../../assets/nodejs_logo.png";
import GoLogo from "../../assets/go_logo.png";
import CLogo from "../../assets/c_logo.png";
import SolidityLogo from "../../assets/solidity_logo.png";
import PythonLogo from "../../assets/python_logo.png";
import JavaLogo from "../../assets/java_logo.png";
import WebAssemblyLogo from "../../assets/web_assembly_logo.png";

import DappBrowserIcon from "../../assets/dapp_browser_icon.png";
import DecAppIcon from "../../assets/dec_app_icon.png";
import ProgLangIcon from "../../assets/prog_lang_icon.png";
import AppHostingIcon from "../../assets/app_hosting_icon.png";

import DataFeedsIcon from "../../assets/data_feeds_icon.png";
import OffChainIcon from "../../assets/off_chain_icon.png";
import MultiSignIcon from "../../assets/multi_sign_icon.png";
import DaosIcon from "../../assets/daos_icon.png";
import StateChannelIcon from "../../assets/state_channels_icon.png";
import OraclesIcon from "../../assets/oracles_icon.png";
import WalletIcon from "../../assets/wallet_icon.png";
import SmartContractIcon from "../../assets/smart_contract_icon.png";
import DigiAssetIcon from "../../assets/digi_asset_icon.png";
import DigiIDIcon from "../../assets/digi_id_icon.png";

import ConscencusIcon from "../../assets/conscencus_icon.png";
import SideChainsIcon from "../../assets/side_chains_icon.png";
import EvmIcon from "../../assets/evm_icon.png";
import PermissionIcon from "../../assets/permission_icon.png";

import RplxIcon from "../../assets/rplx_icon.png";
import RyoIcon from "../../assets/ryo_icon.png";
import TrustedIcon from "../../assets/trusted_icon.png";
import P2PIcon from "../../assets/p2p_icon.png";
import BdnIcon from "../../assets/bdn_icon.png";

import MiningIcon from "../../assets/mining_icon.png";
import NetworkIcon from "../../assets/network_icon.png";
import VirtualizationIcon from "../../assets/virtualization_icon.png";
import TokensIcon from "../../assets/tokens_icon.png";
import NodesIcon from "../../assets/nodes_icon.png";
import StorageIcon from "../../assets/storage_icon.png";

import DaoCreation from "../../assets/dao_creation.png";
import SmartContract from "../../assets/smart_contract.png";
import Tokenomics from "../../assets/tokenomics.png";
import UiUx from "../../assets/ui_ux.png";
import Auditing from "../../assets/auditing.png";

import LinkedInIcon from "../../assets/linkedin_icon.png";
import FbIcon from "../../assets/fb_icon.png";
import BranchIcon from "../../assets/branch_icon.png";
import { NavigateFunction } from "react-router-dom";

export const BLOCK_CHAIN_PLATFORMS = [
    {
        title: "Etherium",
        image: EtheriumLogo
    },
    {
        title: "Hyperledger",
        image: HyperledgerLogo
    },
    {
        title: "Corda",
        image: CordaLogo
    },
    {
        title: "Multichain",
        image: MultichainLogo
    },
    {
        title: "Eosio",
        image: EosioLogo
    },
    {
        title: "Hedera",
        image: HederaLogo
    },
    {
        title: "Stellar",
        image: StellarLogo
    },
    {
        title: "Tron",
        image: TronLogo
    }
];

export const PROGRAMMING_LANGUAGES = [
    {
        title: "Javascript",
        image: JsLogo
    },
    {
        title: "NodeJs",
        image: NodeJsLogo
    },
    {
        title: "Go",
        image: GoLogo
    },
    {
        title: "C++",
        image: CLogo
    },
    {
        title: "Solidity",
        image: SolidityLogo
    },
    {
        title: "Python",
        image: PythonLogo
    },
    {
        title: "Java",
        image: JavaLogo
    },
    {
        title: "Web Assembly",
        image: WebAssemblyLogo
    }
];

export const APPLICATION_LAYER = [
    {
        title: "DApp Browser",
        image: DappBrowserIcon
    },
    {
        title: "Decentralized Application",
        image: DecAppIcon
    },
    {
        title: "Programming Language",
        image: ProgLangIcon
    },
    {
        title: "Application Hosting",
        image: AppHostingIcon
    }
];

export const SERVICES_LAYER = [
    {
        title: "Data Feeds",
        image: DataFeedsIcon
    },
    {
        title: "Off-Chain Computing",
        image: OffChainIcon
    },
    {
        title: "Multi-Signature",
        image: MultiSignIcon
    },
    {
        title: "Governance/DAOS",
        image: DaosIcon
    },
    {
        title: "State Channels",
        image: StateChannelIcon
    },
    {
        title: "Oracles",
        image: OraclesIcon
    },
    {
        title: "Wallets",
        image: WalletIcon
    },
    {
        title: "Smart Contracts",
        image: SmartContractIcon
    },
    {
        title: "Digital Assets",
        image: DigiAssetIcon
    },
    {
        title: "Digital IDs",
        image: DigiIDIcon
    }
];

export const PROTOCOL_LATER = [
    {
        title: "Conscensus Algorithms",
        image: ConscencusIcon
    },
    {
        title: "Side Chains",
        image: SideChainsIcon
    },
    {
        title: "EVMs",
        image: EvmIcon
    },
    {
        title: "Permissioned & Permissionless",
        image: PermissionIcon
    }
];

export const NETWORK_LATER = [
    {
        title: "RPLx",
        image: RplxIcon
    },
    {
        title: "Roll Your Own",
        image: RyoIcon
    },
    {
        title: "Block Delivery Networks",
        image: BdnIcon
    },
    {
        title: "Trusted Execution Environment",
        image: TrustedIcon
    },
    {
        title: "Peer-To-Peer",
        image: P2PIcon
    }
];

export const INFRASTRUCTURE_LATER = [
    {
        title: "Mining",
        image: MiningIcon
    },
    {
        title: "Network",
        image: NetworkIcon
    },
    {
        title: "Virtualization",
        image: VirtualizationIcon
    },
    {
        title: "Tokens",
        image: TokensIcon
    },
    {
        title: "Nodes",
        image: NodesIcon
    },
    {
        title: "Storage",
        image: StorageIcon
    }
];

export const WHAT_WE_DO = [
    {
        title: "DAO Creation",
        image: DaoCreation
    },
    {
        title: "Smart Contract",
        image: SmartContract
    },
    {
        title: "Tokenomics",
        image: Tokenomics
    },
    {
        title: "UI/UX",
        image: UiUx
    },
    {
        title: "Auditing",
        image: Auditing
    }
];

export const getSideBarMenu = ({ navigate }: { navigate?: NavigateFunction }) => [
    {
        header: "SERVICES",
        items: [
            {
                label: "Tokenomics Design & White Paper Dev"
            },
            {
                label: "Smart Contract Development"
            },
            {
                label: "Testing Suite & Auditing"
            },
            {
                label: "UI/UX Dev & Smart Contract Integration",
                divider: true
            },
            {
                label: "About"
            },
            {
                label: "Contact",
                onClick: () => {
                    if (navigate) {
                        navigate("/contact-us");
                    }
                }
            }
        ]
    }
];

export const SOCIALS_ICONS = [
    {
        image: LinkedInIcon
    },
    {
        image: FbIcon
    },
    {
        image: BranchIcon
    }
];

export const commonSliderConfig = {
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 5,
    speed: 500,
    arrows: false,
    initialSlide: 2,
    variableWidth: true,
    className: cn("center", styles.whatWeDoSlider)
};

export const sliderSettings = {
    ...commonSliderConfig,
    className: cn("center", styles.whatWeDoSlider, styles.centerPadding),

    responsive: [
        {
            breakpoint: 790,
            settings: {
                ...commonSliderConfig,
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 800,
            settings: {
                ...commonSliderConfig,
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 820,
            settings: {
                ...commonSliderConfig,
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {
            breakpoint: 1200,
            settings: {
                ...commonSliderConfig,
                slidesToShow: 4,
                slidesToScroll: 4
            }
        }
    ]
};
