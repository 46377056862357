import { Box, BoxProps, Container } from "@mui/material";
import cn from "classnames";
import React from "react";

import styles from "./section.module.scss";

interface SectionProps extends BoxProps {
    children?: React.ReactNode;
}

export const Section = ({ className, children, ...props }: SectionProps) => {
    return (
        <Box className={cn(styles.section, className)} {...props}>
            <Container maxWidth="lg">{children}</Container>
        </Box>
    );
};
