// https://www.webtips.dev/webtips/react-hooks/element-in-viewport
import { useState, useEffect } from "react";

export const useIntersection = (element: React.MutableRefObject<any>, rootMargin: string) => {
    const [isVisible, setState] = useState(false);

    useEffect(() => {
        if (!element) {
            return;
        }

        const currentElement = element.current;

        if (!currentElement) {
            return;
        }

        const observer = new IntersectionObserver(
            ([entry]) => {
                setState(entry.isIntersecting);
            },
            { rootMargin }
        );

        element.current && observer.observe(currentElement);

        return () => observer.unobserve(currentElement);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return isVisible;
};
