import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import { Theme } from "@mui/system";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";

import { useIntersection } from "hooks";
import Slider from "react-slick";
import { BlockchainPlatform, SliderImage } from "./components";

import HeaderBanners1 from "../../assets/headerImage/banners_1.png";
import HeaderBanners2 from "../../assets/headerImage/banners_2.png";
import HeaderBasePlatform from "../../assets/headerImage/base_platform.png";
import HeaderEthLogo from "../../assets/headerImage/header_bc.png";
import HeaderBCLogo from "../../assets/headerImage/header_eth.png";
import HeaderWiseLogo from "../../assets/headerImage/logo.png";

import BitCoinLogo1 from "../../assets/bitcoin_logo_1.png";
import EtheriumLogo1 from "../../assets/etherium_logo_1.png";
import PerspectiveGrid from "../../assets/perspective_grid.png";

import {
    APPLICATION_LAYER,
    BLOCK_CHAIN_PLATFORMS,
    INFRASTRUCTURE_LATER,
    NETWORK_LATER,
    PROGRAMMING_LANGUAGES,
    PROTOCOL_LATER,
    SERVICES_LAYER,
    sliderSettings,
    WHAT_WE_DO
} from "./home.constants";

import ApplicationLayer from "../../assets/application_layer.png";
import CardsComplete from "../../assets/cards_complete.png";
import InfrastructureLayer from "../../assets/infrastructure_layer.png";
import NetworkLayer from "../../assets/network_layer.png";
import ProtocolLayer from "../../assets/protocol_layer.png";
import ServicesLayer from "../../assets/services_layer.png";
import { LayerIcon } from "./components/LayerIcon";

import { Section } from "components";
import styles from "./home.module.scss";

//@ts-ignore
import styleVariables from "../../styles/variables.scss";

const SECTION_VISIBILITY_MARGIN = "-50%";

export const Home = () => {
    const navigate = useNavigate();

    const isMediaBelowSm = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
    const isMediaBelowMd = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

    const appLayerRef = useRef<HTMLDivElement | null>();
    const serviceLayerRef = useRef<HTMLDivElement | null>();
    const protocolLayerRef = useRef<HTMLDivElement | null>();
    const networkLayerRef = useRef<HTMLDivElement | null>();
    const infraLayerRef = useRef<HTMLDivElement | null>();

    const isAppLayerVisible = useIntersection(appLayerRef, SECTION_VISIBILITY_MARGIN);
    const isServiceLayerVisible = useIntersection(serviceLayerRef, SECTION_VISIBILITY_MARGIN);
    const isProtocolLayerVisible = useIntersection(protocolLayerRef, SECTION_VISIBILITY_MARGIN);
    const isNetworkLayerVisible = useIntersection(networkLayerRef, SECTION_VISIBILITY_MARGIN);
    const isInfraLayerVisible = useIntersection(infraLayerRef, SECTION_VISIBILITY_MARGIN);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, []);

    return (
        <>
            <Section
                paddingTop={{
                    xs: "88px",
                    md: "60px"
                }}
            >
                <Grid container display="flex" alignItems="center" alignContent="center">
                    <Grid
                        item
                        md={6}
                        textAlign="left"
                        zIndex={5}
                        order={{
                            md: 1,
                            xs: 2
                        }}
                        xs={12}
                        gap={1}
                    >
                        <Typography variant="h2" my={5} textAlign="left" fontWeight="bold">
                            Token Development &amp; <span style={{ color: styleVariables.blue }}>Web 3.0</span>
                        </Typography>
                        <Typography variant="subtitle1" my={5}>
                            By developing custom smart contracts across all major chains. DeFiDevShop offers dynamic
                            solutions that provide long-term success through a focus on security, upgradeability,
                            interoperability, &#38; efficiency.
                        </Typography>
                        <Button
                            variant="contained"
                            sx={{
                                width: {
                                    xs: "50%",
                                    md: "unset"
                                }
                            }}
                            onClick={() => navigate("/contact-us")}
                        >
                            <div className={styles.actionButton}>Get Started</div>
                        </Button>
                        <Button
                            variant="text"
                            color="primary"
                            sx={{
                                width: {
                                    xs: "50%",
                                    md: "unset"
                                },
                                color: styleVariables.white
                            }}
                            href="#section_what-we-do"
                        >
                            Learn More &gt;
                        </Button>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={6}
                        className={cn(styles.headerImageContainer, {
                            [styles.small]: isMediaBelowMd
                        })}
                        order={{
                            md: 2,
                            xs: 1
                        }}
                    >
                        <img src={HeaderBCLogo} className={cn(styles.headerCoins, styles.bcLogo)} alt="bitcoin" />
                        <img src={HeaderEthLogo} className={cn(styles.headerCoins, styles.ethLogo)} alt="eth" />
                        <img src={HeaderWiseLogo} className={styles.headerWiseLogo} alt="wise" />
                        <img src={HeaderBanners1} className={styles.headerBanners1} alt="banner" />
                        <img src={HeaderBanners2} className={styles.headerBanners2} alt="banner" />
                        <img src={HeaderBasePlatform} className={styles.headerBasePlatform} alt="platform" />
                    </Grid>
                </Grid>
            </Section>
            <Section
                sx={{
                    backgroundImage: `url(${PerspectiveGrid})`,
                    backgroundPosition: "center",
                    backgroundSize: "100% 100%",
                    backgroundRepeat: "no-repeat",
                    backgroundColor: styleVariables.blue
                }}
            >
                <Box
                    margin="auto"
                    width={{
                        md: "50%",
                        xs: "90%"
                    }}
                    textAlign="center"
                >
                    <Typography variant="h2" my={5} fontWeight="bold">
                        <Slider
                            className={styles.textSlider}
                            fade
                            infinite
                            speed={1000}
                            slidesToShow={1}
                            autoplay
                            arrows={false}
                            autoplaySpeed={4000}
                        >
                            {[
                                <>
                                    Over <span style={{ color: styleVariables.cyan }}>2,000 lines</span> of original
                                    code
                                </>,
                                <>
                                    <span style={{ color: styleVariables.cyan }}>$1B+</span> through our protocols
                                </>,
                                <>
                                    <span style={{ color: styleVariables.cyan }}>ZERO</span> exploits on our smart
                                    contracts
                                </>
                            ].map(el => el)}
                        </Slider>
                    </Typography>
                    <Typography variant="subtitle1">
                        Streamline and protect by partnering with DeFiDevShop! We have been delivering smooth efficient
                        Web3 solutions since 2019. Our battle-tested devs will help you avoid costly errors that often
                        trip up inexperienced teams. Additionally, our math PHDs and tokekenomics experts can help mold
                        your overall structure. Whether you are building a financial product, NFTs, or a DAO, we are
                        your end-to-end solution.
                    </Typography>
                </Box>
            </Section>
            <Section id="section_what-we-do" textAlign="center">
                <Typography variant="h2" my={5} fontWeight="bold">
                    What We Do
                </Typography>
                <Typography variant="subtitle1">
                    Whatever you need, we're prepared for the hard stuff. From audits to smart contract development and
                    everything in between, our solution puts the future in your hands today.
                </Typography>
                <Slider {...sliderSettings}>
                    {WHAT_WE_DO.map((slide, index) => (
                        <SliderImage
                            key={index}
                            className={styles.sliderTablet}
                            title={slide.title}
                            image={slide.image}
                        />
                    ))}
                </Slider>
            </Section>
            <Section>
                <Grid container spacing={5} position="relative">
                    <Grid item md={6} zIndex={100}>
                        <Typography variant="h2" my={5} textAlign="left" fontWeight="bold">
                            The blockchain platforms and tech stack we trust
                        </Typography>
                        <Typography variant="subtitle1">
                            Our blockchain developers know what it takes to power up your business in a decentralized
                            world. We work with different sets of tools and components to make your Dapps solutions
                            successful.
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        md={6}
                        xs={12}
                        className={cn({
                            [styles.blockchainFloatingImageContainer]: isMediaBelowSm
                        })}
                    >
                        <Box position="relative" height="300px">
                            <Box
                                className={cn(styles.blockchainFloatingImage, styles.delay1)}
                                left={{ xs: "10%", sm: "25%", md: "20%" }}
                            >
                                <Box component="img" src={EtheriumLogo1} />
                            </Box>
                            <Box
                                className={cn(styles.blockchainFloatingImage, styles.delay2)}
                                top="35%"
                                right={{
                                    xs: "5%",
                                    sm: "25%",
                                    md: "15%"
                                }}
                            >
                                <Box component="img" src={BitCoinLogo1} />
                            </Box>
                            <Box
                                className={cn(styles.blockchainFloatingImage, styles.delay3, styles.floatingImageSmall)}
                                bottom={0}
                                left={{
                                    xs: "25%",
                                    sm: "35%"
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Section>
            <Section paddingBottom={0}>
                <Typography variant="h3" my={5} fontWeight="bold">
                    Blockchain Platforms
                </Typography>
                <Grid container spacing={5} alignItems="center" justifyContent="center" alignContent="center">
                    {BLOCK_CHAIN_PLATFORMS.map((blockChainPlatform, index) => (
                        <Grid key={index} item md={3} xs={6}>
                            <BlockchainPlatform title={blockChainPlatform.title} image={blockChainPlatform.image} />
                        </Grid>
                    ))}
                </Grid>
            </Section>
            <Section paddingTop={0}>
                <Typography variant="h3" my={5} fontWeight="bold">
                    Programming Languages
                </Typography>
                <Grid container spacing={5} alignItems="center" justifyContent="center" alignContent="center">
                    {PROGRAMMING_LANGUAGES.map((blockChainPlatform, index) => (
                        <Grid key={index} item md={3} xs={6}>
                            <BlockchainPlatform title={blockChainPlatform.title} image={blockChainPlatform.image} />
                        </Grid>
                    ))}
                </Grid>
            </Section>
            <Section>
                <Grid container alignItems="center" justifyContent="center" alignContent="center">
                    <Grid
                        item
                        xs={12}
                        md={7}
                        order={{
                            md: 1,
                            xs: 2
                        }}
                        textAlign="left"
                    >
                        <Typography variant="h2" my={5} fontWeight="bold">
                            System Architecture
                        </Typography>
                        <Typography variant="subtitle1">
                            With DeFiDevShop, the software is structured into layers to help create a foundation for
                            your development. Each layer provides services to the next higher level. allowing teams to
                            focus on their most impotant parts.
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        md={5}
                        xs={12}
                        order={{
                            md: 2,
                            xs: 1
                        }}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        alignContent="center"
                    >
                        <Box
                            component="img"
                            width={{
                                xs: "75%",
                                md: "90%"
                            }}
                            src={CardsComplete}
                        />
                    </Grid>
                </Grid>
            </Section>
            <Section>
                <Grid
                    container
                    display="flex"
                    alignItems="center"
                    alignContent="center"
                    textAlign="right"
                    ref={el => (appLayerRef.current = el)}
                >
                    <Grid item xs={12} md={7} order={2}>
                        <Typography
                            variant="h2"
                            my={5}
                            fontWeight="bold"
                            className={cn(styles.positionRelative, {
                                [styles.fadeInRight]: isAppLayerVisible,
                                [styles.fadeOutLeft]: !isAppLayerVisible
                            })}
                        >
                            Application Layer
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            className={cn(styles.positionRelative, {
                                [styles.fadeInLeft]: isAppLayerVisible,
                                [styles.fadeOutRight]: !isAppLayerVisible
                            })}
                        >
                            The User Interface which combines customer interactions with business logic
                        </Typography>
                        <Box
                            sx={{
                                display: "grid",
                                gridTemplateColumns: {
                                    xs: "repeat(3, 30%)",
                                    md: "repeat(4, 100px)"
                                },
                                justifyContent: {
                                    md: "flex-end"
                                },
                                gridGap: "15px",
                                my: 5
                            }}
                            className={cn(styles.positionRelative, {
                                [styles.fadeInLeft]: isAppLayerVisible,
                                [styles.fadeOutRight]: !isAppLayerVisible
                            })}
                        >
                            {APPLICATION_LAYER.map(layer => (
                                <LayerIcon title={layer.title} image={layer.image} />
                            ))}
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={5}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        alignContent="center"
                    >
                        <Box
                            component="img"
                            width={{
                                xs: "50%",
                                md: "60%"
                            }}
                            src={ApplicationLayer}
                            className={cn(styles.positionRelative, {
                                [styles.fadeInRight]: isAppLayerVisible,
                                [styles.fadeOutLeft]: !isAppLayerVisible
                            })}
                        />
                    </Grid>
                </Grid>
            </Section>
            <Section>
                <Grid
                    container
                    display="flex"
                    alignItems="center"
                    alignContent="center"
                    textAlign="left"
                    ref={el => (serviceLayerRef.current = el)}
                >
                    <Grid
                        item
                        xs={12}
                        md={7}
                        order={{
                            md: 1,
                            xs: 2
                        }}
                    >
                        <Typography
                            variant="h2"
                            my={5}
                            fontWeight="bold"
                            className={cn(styles.positionRelative, {
                                [styles.fadeInLeft]: isServiceLayerVisible,
                                [styles.fadeOutRight]: !isServiceLayerVisible
                            })}
                        >
                            Services and Optional Components
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            className={cn(styles.positionRelative, {
                                [styles.fadeInRight]: isServiceLayerVisible,
                                [styles.fadeOutLeft]: !isServiceLayerVisible
                            })}
                        >
                            Provides a view to connect application operations with other platforms and technologies
                        </Typography>
                        <Box
                            sx={{
                                display: "grid",
                                gridTemplateColumns: {
                                    xs: "repeat(3, 30%)",
                                    md: "repeat(5, 100px)"
                                },
                                gridGap: "15px",
                                my: 5,
                                justifyContent: {
                                    md: "flex-start"
                                }
                            }}
                            className={cn(styles.positionRelative, {
                                [styles.fadeInRight]: isServiceLayerVisible,
                                [styles.fadeOutLeft]: !isServiceLayerVisible
                            })}
                        >
                            {SERVICES_LAYER.map(layer => (
                                <LayerIcon title={layer.title} image={layer.image} />
                            ))}
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={5}
                        order={{
                            md: 2,
                            xs: 1
                        }}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        alignContent="center"
                    >
                        <Box
                            component="img"
                            width={{
                                xs: "75%",
                                md: "90%"
                            }}
                            src={ServicesLayer}
                            className={cn(styles.positionRelative, {
                                [styles.fadeInLeft]: isServiceLayerVisible,
                                [styles.fadeOutRight]: !isServiceLayerVisible
                            })}
                        />
                    </Grid>
                </Grid>
            </Section>
            <Section>
                <Grid
                    container
                    display="flex"
                    alignItems="center"
                    alignContent="center"
                    textAlign="right"
                    ref={el => (protocolLayerRef.current = el)}
                >
                    <Grid item xs={12} md={7} order={2}>
                        <Typography
                            variant="h2"
                            my={5}
                            fontWeight="bold"
                            className={cn(styles.positionRelative, {
                                [styles.fadeInRight]: isProtocolLayerVisible,
                                [styles.fadeOutLeft]: !isProtocolLayerVisible
                            })}
                        >
                            Protocol Layer
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            className={cn(styles.positionRelative, {
                                [styles.fadeInLeft]: isProtocolLayerVisible,
                                [styles.fadeOutRight]: !isProtocolLayerVisible
                            })}
                        >
                            Decides the process of network participation and consensus
                        </Typography>
                        <Box
                            sx={{
                                display: "grid",
                                gridTemplateColumns: {
                                    xs: "repeat(3, 30%)",
                                    md: "repeat(4, 100px)"
                                },
                                justifyContent: {
                                    md: "flex-end"
                                },
                                gridGap: "15px",
                                my: 5
                            }}
                            className={cn(styles.positionRelative, {
                                [styles.fadeInLeft]: isProtocolLayerVisible,
                                [styles.fadeOutRight]: !isProtocolLayerVisible
                            })}
                        >
                            {PROTOCOL_LATER.map(layer => (
                                <LayerIcon title={layer.title} image={layer.image} />
                            ))}
                        </Box>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        md={5}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        alignContent="center"
                    >
                        <Box
                            component="img"
                            width={{
                                xs: "75%",
                                md: "90%"
                            }}
                            src={ProtocolLayer}
                            className={cn(styles.positionRelative, {
                                [styles.fadeInRight]: isProtocolLayerVisible,
                                [styles.fadeOutLeft]: !isProtocolLayerVisible
                            })}
                        />
                    </Grid>
                </Grid>
            </Section>
            <Section>
                <Grid
                    container
                    display="flex"
                    alignItems="center"
                    alignContent="center"
                    spacing={10}
                    ref={el => (networkLayerRef.current = el)}
                >
                    <Grid
                        item
                        xs={12}
                        md={7}
                        order={{
                            md: 1,
                            xs: 2
                        }}
                        textAlign="left"
                    >
                        <Typography
                            variant="h2"
                            my={5}
                            fontWeight="bold"
                            className={cn(styles.positionRelative, {
                                [styles.fadeInRight]: isNetworkLayerVisible,
                                [styles.fadeOutLeft]: !isNetworkLayerVisible
                            })}
                        >
                            Network Layer
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            className={cn(styles.positionRelative, {
                                [styles.fadeInLeft]: isNetworkLayerVisible,
                                [styles.fadeOutRight]: !isNetworkLayerVisible
                            })}
                        >
                            An interface and transportation medium for a peer-to-peer network, which helps decide how
                            the data will be packetized.
                        </Typography>
                        <Box
                            sx={{
                                display: "grid",
                                justifyContent: {
                                    md: "flex-start"
                                },
                                gridTemplateColumns: {
                                    xs: "repeat(3, 30%)",
                                    md: "repeat(5, 100px)"
                                },
                                gridGap: "15px",
                                my: 5
                            }}
                            className={cn(styles.positionRelative, {
                                [styles.fadeInLeft]: isNetworkLayerVisible,
                                [styles.fadeOutRight]: !isNetworkLayerVisible
                            })}
                        >
                            {NETWORK_LATER.map(layer => (
                                <LayerIcon title={layer.title} image={layer.image} />
                            ))}
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={5}
                        order={{
                            md: 2,
                            xs: 1
                        }}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        alignContent="center"
                    >
                        <Box
                            component="img"
                            width={{
                                xs: "75%",
                                md: "90%"
                            }}
                            src={NetworkLayer}
                            className={cn(styles.positionRelative, {
                                [styles.fadeInLeft]: isNetworkLayerVisible,
                                [styles.fadeOutRight]: !isNetworkLayerVisible
                            })}
                        />
                    </Grid>
                </Grid>
            </Section>
            <Section>
                <Grid
                    container
                    display="flex"
                    alignItems="center"
                    alignContent="center"
                    textAlign="right"
                    spacing={1}
                    ref={el => (infraLayerRef.current = el)}
                >
                    <Grid item xs={12} md={7} order={2}>
                        <Typography
                            variant="h2"
                            my={5}
                            fontWeight="bold"
                            className={cn(styles.positionRelative, {
                                [styles.fadeInRight]: isInfraLayerVisible,
                                [styles.fadeOutLeft]: !isInfraLayerVisible
                            })}
                        >
                            Infrastructure Layer
                        </Typography>
                        <Typography
                            variant="subtitle1"
                            className={cn(styles.positionRelative, {
                                [styles.fadeInLeft]: isInfraLayerVisible,
                                [styles.fadeOutRight]: !isInfraLayerVisible
                            })}
                        >
                            The in-house -20% as a service that controls all the nodes.
                        </Typography>
                        <Box
                            sx={{
                                display: "grid",
                                gridTemplateColumns: {
                                    xs: "repeat(3, 30%)",
                                    md: "repeat(3, 100px)"
                                },
                                justifyContent: {
                                    md: "flex-end"
                                },
                                gridGap: "15px",
                                my: 5
                            }}
                            className={cn(styles.positionRelative, {
                                [styles.fadeInLeft]: isInfraLayerVisible,
                                [styles.fadeOutRight]: !isInfraLayerVisible
                            })}
                        >
                            {INFRASTRUCTURE_LATER.map(layer => (
                                <LayerIcon title={layer.title} image={layer.image} />
                            ))}
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={5}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            alignContent: "center"
                        }}
                    >
                        <Box
                            component="img"
                            width={{
                                xs: "75%",
                                md: "90%"
                            }}
                            src={InfrastructureLayer}
                            className={cn(styles.positionRelative, {
                                [styles.fadeInRight]: isInfraLayerVisible,
                                [styles.fadeOutLeft]: !isInfraLayerVisible
                            })}
                        />
                    </Grid>
                </Grid>
            </Section>
            <Section
                sx={{
                    backgroundImage: `url(${PerspectiveGrid})`,
                    backgroundPosition: "center",
                    backgroundSize: "100% 100%",
                    backgroundRepeat: "no-repeat",
                    backgroundColor: "transparent",
                    margin: "unset"
                }}
            >
                <Box
                    width={{
                        md: "60%",
                        xs: "90%"
                    }}
                    margin="auto"
                    textAlign="center"
                >
                    <Typography variant="h2" my={5} fontWeight="bold">
                        Have a project in mind? Let's get to work.
                    </Typography>
                    <Button variant="contained" size="large" onClick={() => navigate("/contact-us")}>
                        Start Project
                    </Button>
                </Box>
            </Section>
        </>
    );
};
