import "./styles/app.sass";
import { StyledEngineProvider } from "@mui/material/styles";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ContactUs, Home } from "pages";
import { ParallaxProvider } from "react-scroll-parallax";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { muiTheme } from "styles/theme/mui";
import { DefiDevShopAppBar, Footer } from "components";

//@ts-ignore
import styleVariables from "./styles/variables.scss";

function App() {
    return (
        <Router>
            <div
                style={{
                    backgroundColor: styleVariables.darkblue
                }}
            >
                <ParallaxProvider>
                    <CssBaseline />
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={muiTheme}>
                            <div
                                style={{
                                    backgroundImage: `linear-gradient(${styleVariables.darkblue} 50%, ${styleVariables.blue})`
                                }}
                            >
                                <DefiDevShopAppBar />
                                <Routes>
                                    <Route path="/contact-us" element={<ContactUs />}></Route>
                                    <Route path="/" element={<Home />}></Route>
                                </Routes>
                                <Footer />
                            </div>
                        </ThemeProvider>
                    </StyledEngineProvider>
                </ParallaxProvider>
            </div>
        </Router>
    );
}

export default App;
