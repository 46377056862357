import { Drawer as MuiDrawer, DrawerProps } from "@mui/material";
import { FC } from "react";

import styles from "./drawer.module.scss";

//@ts-ignore
import styleVariables from "../../styles/variables.scss";

export const Drawer: FC<DrawerProps> = ({ open, onClose, anchor = "right", children }) => {
    return (
        <MuiDrawer
            anchor={anchor}
            open={open}
            onClose={onClose}
            PaperProps={{
                className: styles.container,
                sx: {
                    padding: {
                        xs: "10%",
                        md: "5%"
                    },
                    color: styleVariables.white
                }
            }}
        >
            {children}
        </MuiDrawer>
    );
};
