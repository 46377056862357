import { Box, Divider, Grid, List, ListItem, Typography } from "@mui/material";
import { Section } from "components/Section";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import DefiDevShopIcon from "../../assets/defidevshopicon";

// import { SOCIALS_ICONS } from "./footer.constants";

import styles from "./footer.module.scss";

export const Footer = () => {
    return (
        <Section className={styles.container}>
            <Grid
                container
                textAlign="left"
                spacing={{
                    md: 20,
                    xs: 5
                }}
            >
                {/* <Grid item md={3} xs={12}> */}
                <Grid item md={6} xs={12}>
                    <DefiDevShopIcon width={50} height={50} />
                    <Typography>Providing solutions that are built to last.</Typography>
                    <Typography>-</Typography>
                    <Typography>info@defidevshop.com</Typography>
                </Grid>
                {/* <Grid item md={3} xs={12}> */}
                <Grid item md={6} xs={12}>
                    <Typography variant="subtitle1" fontWeight="bold">
                        What we do
                    </Typography>
                    <List className={styles.linkList}>
                        <ListItem button component={Link} to="/">
                            Home
                        </ListItem>
                        <ListItem button component={HashLink} to="/#section_what-we-do">
                            Services
                        </ListItem>
                        <ListItem button component={Link} to="/contact-us">
                            Contact Us
                        </ListItem>
                    </List>
                </Grid>
                {/* <Grid item md={3} xs={12}>
					<Typography variant="subtitle1" fontWeight="bold">
						Who we are
					</Typography>
					<List className={styles.linkList}>
						<ListItem>About</ListItem>
					</List>
				</Grid>
				<Grid item md={3} xs={12}>
					<Typography variant="subtitle1" fontWeight="bold">
						Follow us
					</Typography>
					<List className={styles.linkList}>
						<ListItem>Faceboook</ListItem>
						<ListItem>LinkedIn</ListItem>
						<ListItem>Instagram</ListItem>
						<ListItem>Twitter</ListItem>
					</List>
				</Grid> */}
            </Grid>
            <Divider color="white" variant="fullWidth" sx={{ my: "30px" }} />
            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: {
                        // md: "repeat(3, 1fr)",
                        md: "repeat(2, 1fr)",
                        xs: "repeat(1, 1fr)"
                    },
                    gap: {
                        xs: 1
                    }
                }}
            >
                <Box
                    textAlign={{
                        md: "left",
                        xs: "center"
                    }}
                >
                    <Typography variant="subtitle1">© DeFiDevShop 2022. All Rights Reserved.</Typography>
                </Box>
                {/* <Box
                    sx={{
                        display: "flex",
                        gap: "30px",
                        justifyContent: "center",
                    }}
                >
                    <Typography variant="subtitle1">Terms</Typography>
                    <Typography variant="subtitle1">Privacy</Typography>
                    <Typography variant="subtitle1">Cookies</Typography>
                </Box>
                 <Box
                    textAlign={{
                        md: "right",
                        xs: "center",
                    }}
                >
                    <Typography variant="subtitle1">
                        {SOCIALS_ICONS.map(social => (
                            <Box
                                sx={{
                                    border: "1px solid",
                                    borderRadius: "100%",
                                    padding: "5px",
                                    m: 1,
                                }}
                                component="img"
                                src={social.image}
                            />
                        ))}
                    </Typography>
                </Box> */}
            </Box>
        </Section>
    );
};
