import { ChangeEvent, useState } from "react";
import axios from "axios";
import {
    Alert,
    AlertColor,
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormHelperText,
    FormLabel,
    Grid,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Snackbar,
    ThemeProvider,
    Typography,
} from "@mui/material";
import { Section } from "components";

import cn from "classnames";

import PerspectiveGrid from "../../assets/perspective_grid_3.svg";
import { darkMuiTheme } from "styles/theme/mui";

import { FormKeyValue, Toast } from "./types";

//@ts-ignore
import styles from "./contactus.module.scss";

const GS_URL =
    "https://script.google.com/macros/s/AKfycbxvmUUpv7owjcHrC-X0mDz7DLTkGgx76x309EdfIljrClHsaYHbPpDQxRfOnY7Syg7n/exec";

// TODO: use form libraries
const REQUIRED_FIELDS = ["fullname", "email"];

export const ContactUs = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [toast, setToast] = useState<Toast>();
    const [inputs, setInputs] = useState<FormKeyValue<string>>();
    const [errors, setErrors] = useState<FormKeyValue<boolean>>();

    const handleChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
    ) => {
        const name = event.target.name;
        const value = event.target.value;

        REQUIRED_FIELDS.includes(name) && setErrors(prev => ({
            ...prev,
            [name]: !value?.length,
        }));

        setInputs(values => ({ ...values, [name]: value }));
    };

    const validate = () => {
        let newErrors = {
            ...errors,
        };
        REQUIRED_FIELDS.forEach(field => {
            if (!inputs?.[field]?.length) {
                newErrors = {
                    ...newErrors,
                    [field]: true,
                };
            }
        });
        setErrors(newErrors);
        return newErrors && Object.values(newErrors).some(error => error);
    };

    const handleSubmit = async () => {
        if (validate()) {
            return;
        }
        setIsLoading(true);
        try {
            const inputKeys = inputs && Object.keys(inputs);

            if (inputKeys?.length) {
                const form = new FormData();
                inputKeys.forEach(
                    field => {
                        form.append(field, inputs?.[field] || "");
                    }
                );
                await axios.post(GS_URL, form);
                setInputs({});
                setErrors({});
                setToast({
                    open: true,
                    message: "Submitted!",
                    variant: "success",
                });
            }

        } catch (ex) {
            console.log(ex);
            setToast({
                open: true,
                message: "Error!",
                variant: "error",
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <ThemeProvider theme={darkMuiTheme}>
            <Section
                className={styles.container}
                sx={{
                    backgroundImage: `url(${PerspectiveGrid})`
                }}
            >
                <Box width="70%" className={cn(styles.formContainer)}>
                    <Typography variant="h1" fontWeight={700}>
                        Let's start a project together
                    </Typography>
                    <Typography variant="subtitle1" fontWeight={400} marginTop={3}>
                        We'll ensure that your business benefits by partnering with us. Please share your details in the form below,
                        and we'll respond within 48 hours of receiving the inquiry
                    </Typography>
                    <Grid container spacing={2} marginTop={2}>
                        <Grid item xs={12}>
                            <FormControl disabled={isLoading}>
                                <FormLabel>Full Name</FormLabel>
                                <OutlinedInput
                                    name="fullname"
                                    value={inputs?.fullname || ""}
                                    onChange={handleChange}
                                    color="secondary"
                                />
                                {errors?.fullname && <FormHelperText variant="outlined">Required</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl disabled={isLoading}>
                                <FormLabel>Phone Number</FormLabel>
                                <OutlinedInput name="phone" value={inputs?.phone || ""} onChange={handleChange} color="secondary" />
                                {errors?.phone && <FormHelperText variant="outlined">Required</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl disabled={isLoading}>
                                <FormLabel>Email</FormLabel>
                                <OutlinedInput name="email" value={inputs?.email || ""} onChange={handleChange} color="secondary" />
                                {errors?.email && <FormHelperText variant="outlined">Required</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl disabled={isLoading}>
                                <FormLabel>Company Name</FormLabel>
                                <OutlinedInput name="company" value={inputs?.company || ""} onChange={handleChange} color="secondary" />
                                {errors?.company && <FormHelperText variant="outlined">Required</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl disabled={isLoading}>
                                <FormLabel>What kind of service are you looking for?</FormLabel>
                                <Select
                                    color="secondary"
                                    name="service"
                                    value={inputs?.service || ""}
                                    onChange={handleChange}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "left"
                                        },
                                    }}
                                >
                                    <MenuItem value="DAO Creation">DAO Creation</MenuItem>
                                    <MenuItem value="Smart Contract">Smart Contract</MenuItem>
                                    <MenuItem value="Tokenomics">Tokenomics</MenuItem>
                                    <MenuItem value="UI/UX Design">UI/UX Design</MenuItem>
                                    <MenuItem value="Auditing">Auditing</MenuItem>
                                </Select>
                                {errors?.service && <FormHelperText variant="outlined">Required</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl disabled={isLoading}>
                                <FormLabel>Tell us a little more about your goal of partnering with us</FormLabel>
                                <OutlinedInput
                                    name="notes"
                                    value={inputs?.notes || ""}
                                    onChange={handleChange}
                                    multiline
                                    minRows={5}
                                    color="secondary"
                                />
                                {errors?.notes && <FormHelperText variant="outlined">Required</FormHelperText>}
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} display="flex" justifyContent="flex-end">
                            <Button variant="contained" onClick={handleSubmit} disabled={isLoading}>
                                {isLoading && <CircularProgress color="secondary" size={20} sx={{ marginRight: 2 }} />}
                                Send Inquiry
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Section>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={toast?.open}
                autoHideDuration={3000}
                onClose={() =>
                    setToast(prev => ({
                        ...prev,
                        message: "",
                        open: false,
                    }))
                }
            >
                <Alert severity={toast?.variant as AlertColor} sx={{ width: "100%" }}>
                    {toast?.message}
                </Alert>
            </Snackbar>
        </ThemeProvider>
    );
};
