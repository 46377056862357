import { Avatar, Box, Paper, Typography } from "@mui/material";

import styles from "./platform.module.scss";

// @ts-ignore
import styleVariables from "../../../../styles/variables.scss";

interface BlockchainPlatformProps {
    title: string;
    image: string;
}

export const BlockchainPlatform = ({ title, image }: BlockchainPlatformProps) => {
    return (
        <Box className={styles.container}>
            <Avatar
                alt={title}
                src={image}
                imgProps={{
                    sx: {
                        width: "70%",
                        height: "unset"
                    }
                }}
                className={styles.image}
            />
            <Paper
                className={styles.content}
                elevation={3}
                sx={{
                    borderRadius: 2,
                    backgroundColor: "unset"
                }}
            >
                <Typography
                    variant="h5"
                    sx={{
                        color: styleVariables.white
                    }}
                >
                    {title}
                </Typography>
            </Paper>
        </Box>
    );
};
