const DeFiDevShopLogo = ({ width = 165, height = 151 }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 165 151" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M88.976 60.2679L74.1833 75.0605L88.976 89.8532L103.769 75.0605L88.976 60.2679Z" fill="#4A37FF" />
            <path
                d="M88.98 0V31.83C100.445 31.83 111.441 36.3846 119.548 44.4918C127.655 52.599 132.21 63.5947 132.21 75.06C132.21 86.5253 127.655 97.521 119.548 105.628C111.441 113.735 100.445 118.29 88.98 118.29V150.12C108.887 150.12 127.979 142.212 142.055 128.135C156.132 114.059 164.04 94.9671 164.04 75.06C164.04 55.1528 156.132 36.061 142.055 21.9846C127.979 7.90808 108.887 0 88.98 0V0Z"
                fill="#4A37FF"
            />
            <path d="M11.8201 80.9001V69.0801H6.10352e-05V80.9001H11.8201Z" fill="#4A37FF" />
            <path d="M43.8701 60.27V39.02H22.6201V60.27H43.8701Z" fill="#4A37FF" />
            <path d="M22.6199 89.8501V111.1H43.8699V89.8501H22.6199Z" fill="#4A37FF" />
            <path d="M66.48 21.6701V9.8501H54.66V21.6701H66.48Z" fill="#4A37FF" />
            <path d="M54.6699 128.18V140H66.4899V128.18H54.6699Z" fill="#4A37FF" />
        </svg>
    );
};

export default DeFiDevShopLogo;
